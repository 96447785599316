import React, {ReactElement, useState} from "react";
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Container,
    FormControlLabel,
    FormGroup, Link,
    Stack,
    Typography
} from "@mui/material";
import {useMutation, useQuery} from "react-fetching-library";
import {QueryError} from "../../components/Error/QueryError";
import {AuthContext} from "../../index";
import {useNavigate} from "react-router-dom";
import {theme} from "../../../theme";
import {useTranslation} from "react-i18next";

export function EulaAgreementPage(): ReactElement {
    const {t} = useTranslation()
    const auth = React.useContext(AuthContext)
    const navigate = useNavigate()
    const [confirm, setConfirm] = useState(false)
    const [errorMessage, setErrorMessage] = useState<string | undefined>()

    const {
        loading: submitLoading,
        error: submitError,
        mutate: submit,
    } = useMutation((values: { confirm: boolean }) => ({
        method: 'POST',
        endpoint: '/eula/agreement',
        body: {
            confirm: values.confirm
        }
    }))

    const {loading: refreshLoading, error: refreshError, query: refreshQuery} = useQuery<{ token: string }>(
        {
            method: 'GET',
            endpoint: '/user/jwt/refresh',
            cache: 'no-cache',
        }, false
    )

    const handleSubmit = (): void => {
        if (confirm) {
            setErrorMessage(undefined)
            submit({
                confirm: true
            }).then((response) => {
                if (response && response.status === 201) {
                    refreshQuery().then((response) => {
                        if (response && response.payload) {
                            const token = response.payload.token
                            auth.refreshToken(token)
                        }
                    })
                }
            })
        } else {
            setErrorMessage(t('eulaAgreement.errorMessage'))
        }
    }

    if (submitError) return <QueryError/>
    if (refreshError) return <QueryError/>

    return (
        <Container maxWidth="md">
            <Stack
                flexDirection={'column'}
                sx={{
                    height: '95dvh',
                }}
            >
                <Box flex={0.38}></Box>
                <Stack spacing={5}>
                    <Typography variant="h5" textAlign="center">
                        {t('eulaAgreement.title')}
                    </Typography>
                    <Stack justifyContent="center" alignItems="center" spacing={2}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        sx={{
                                            color: errorMessage !== undefined ? theme.palette.error.main : theme.palette.grey[800],
                                            '&.Mui-checked': {
                                                color: errorMessage !== undefined ? theme.palette.error.main : theme.palette.primary.main
                                            },
                                        }}
                                        color="error"
                                        value={confirm}
                                        onChange={(event, checked) => {
                                            setConfirm(checked)
                                        }}
                                    />
                                }
                                label={
                                    <Typography
                                        color={errorMessage !== undefined ? theme.palette.error.main : undefined}
                                    >
                                        {t('eulaAgreement.label')}
                                        <Link
                                            href="https://wiki.besoft.sk/sk/licencie-podmienky/elearning-user"
                                            target="_blank"
                                        >
                                            {t('eulaAgreement.link')}
                                        </Link>
                                    </Typography>
                                }
                            />
                        </FormGroup>
                        {errorMessage &&
                            <Typography
                                variant="subtitle2"
                                sx={{
                                    color: theme.palette.error.main
                                }}
                            >
                                {errorMessage}
                            </Typography>
                        }
                        {(submitLoading || refreshLoading) ?
                            <CircularProgress/>
                            :
                            <Stack direction="row" spacing={3}>
                                <Button
                                    variant="contained"
                                    onClick={handleSubmit}
                                >
                                    {t('common.send')}
                                </Button>
                                <Button
                                    variant="contained"
                                    color="error"
                                    onClick={() => auth.signOut(() => navigate('/'))}
                                >
                                    {t('common.cancel')}
                                </Button>
                            </Stack>
                        }
                    </Stack>
                </Stack>
                <Box flex={1}></Box>
            </Stack>
        </Container>
    )

}