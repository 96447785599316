import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import en from './en/translation.json'
import sk from './sk/translation.json'
import cs from './cs/translation.json'
import pl from './en/translation.json'
import hu from './en/translation.json'
import bg from './en/translation.json'
import ro from './en/translation.json'

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        resources: {
            sk: {
                translation: sk
            },
            cs: {
                translation: cs
            },
            en: {
                translation: en
            },
            pl: {
                translation: pl
            },
            hu: {
                translation: hu
            },
            bg: {
                translation: bg
            },
            ro: {
                translation: ro
            }
        },
        debug: false,
        fallbackLng: 'sk',
        interpolation: {
            escapeValue: false // not needed for react as it escapes by default
        }
    })

export default i18n
