import { Action } from 'react-fetching-library'

export const RequestHostInterceptor = (
    host: string,
    locale: string,
    token: string | null | undefined,

) => () => async (action: Action) => {
    //Locale
    const headers: { [key: string]: string } = {
        ...action.headers, ...{
            'Accept-Language': locale,
            'accept': 'application/json',
        },
    }
    
    if (!action.config?.skipAuth && token) {
        headers['Authorization'] = 'Bearer ' + token;
    }

    return {
        ...action,
        endpoint: `${host}${action.endpoint}`, //host
        headers: headers,
    }
}
