import React from 'react'
import {Navigate, Outlet, useLocation} from 'react-router-dom'
import {AuthContext} from "../app";
import {EulaAgreementPage} from "../app/pages/EulaAgreementPage";

interface Props {
    loginPath: string
}

export function RequireAuth(props: Props): React.ReactElement {
    const location = useLocation()
    const auth = React.useContext(AuthContext)

    const showEula = (token: string | undefined): boolean => {
        if(!token) return false;
        const tokenPayload = token.split('.')[1]
        if(tokenPayload === undefined) return false
        const isEulaAgreed = JSON.parse(atob(tokenPayload)).eula
        return isEulaAgreed === false;
    }

    if (!auth?.user?.token) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
       return <Navigate to={props.loginPath} state={{ from: location }} replace />
    }

    if (showEula(auth.user?.token)) {
        return <EulaAgreementPage/>
    }

    return <Outlet />
}
