import palette from './palette'
import { createShadow } from './shadows'

import { createTheme } from '@mui/material'

export const theme = createTheme({
    palette,
    shape: {
        borderRadius: 8,
    },
    shadows: createShadow(palette.grey[500]),
    breakpoints: {
        values: {
            xs: 0,
            sm: 700,
            md: 990,
            lg: 1350,
            xl: 1536,
        },
    },
})
