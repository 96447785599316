import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import { App } from './app'
import './locales/i18n'
import { Buffer } from 'buffer'

// https://github.com/marcin-piela/react-fetching-library/issues/158 | https://stackoverflow.com/questions/68707553/uncaught-referenceerror-buffer-is-not-defined
window.Buffer = Buffer

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
)
