import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material'
import { MessageContext } from '../context/MessageContextProvider'

export const MODAL_DIALOG = 'modal_dialog'

export function ModalDialog (): React.ReactElement {
    const messageContext = React.useContext(MessageContext)

    return (
        <Dialog
            open={messageContext.isModalOpen(MODAL_DIALOG)}
            sx={{ padding: 5 }}
        >
            <DialogTitle>
                <Stack justifyContent='center' alignItems='center' spacing={1}>
                    {messageContext.icon}
                    <Typography variant='h6'>{messageContext.headTitle}</Typography>
                    <Typography variant='h6'>{messageContext.title}</Typography>
                </Stack>
            </DialogTitle>
            {messageContext.message &&
                <DialogContent>
                    <Typography textAlign='center' variant='subtitle1' whiteSpace='break-spaces'>
                        {messageContext.message}
                    </Typography>
                </DialogContent>
            }
            <DialogActions>
                <Stack direction={"row"} justifyContent="space-between" alignItems="start">
                    {messageContext.button1}
                    {messageContext.button2 && messageContext.button2}
                </Stack>
            </DialogActions>
        </Dialog>
    )
}
