import React, { useContext, useEffect, useState } from 'react'
import { TestContextType } from '../../../../types/context/TestContextType'
import { QueryResponse, useMutation, useQuery } from 'react-fetching-library'
import { Test } from '../../../../types/Test/Test'
import { useNavigate, useParams } from 'react-router-dom'
import { EvaluateTest, TestAnswer } from '../../../../types/Request/EvaluateTest'
import { EvaluatedTest } from '../../../../types/Response/EvaluatedTest'
import { MessageContext } from '../../../context/MessageContextProvider'
import { useTranslation } from 'react-i18next'
import { MODAL_DIALOG } from '../../../components/ModalDialog'
import { MessageType } from '../../../../types/context/MessageContextType'

export const TestContext = React.createContext<TestContextType | null>(null)

interface TestAnswers extends Map<number, TestAnswer> {
}

interface Props {
    children?: React.ReactNode
    trainingId?: number
}

export const TestContextProvider = (props: Props): JSX.Element => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const messageContext = useContext(MessageContext)
    const [test, setTest] = useState<Test | null>(null)
    const [evaluatedTest, setEvaluatedTest] = useState<EvaluatedTest | null>(null)
    const [testAnswers, setTestAnswers] = useState<TestAnswers>(new Map())
    /**training id */
    const urlCourseId: string | undefined = useParams().courseId
    let courseId: number | undefined = props.trainingId
    /** if we don't have id from props then try to get id from url.*/
    if (courseId === undefined && urlCourseId !== undefined) {
        courseId = parseInt(urlCourseId)
    }
    if (courseId === null) {
        throw new Error('You must provide "Training Id" for TestContextProvider')
    }

    const { payload, loading, error } = useQuery<Test>(
        {
            method: 'GET',
            endpoint: `/test/${courseId}`,
            cache: 'no-cache',
        },
    )

    const {
        loading: evaluateTestIsLoading,
        error: evaluateTestError,
        mutate: evaluateTest,
    } = useMutation<EvaluatedTest, {}, EvaluateTest>((values: EvaluateTest) => ({
        method: 'POST',
        endpoint: `/test/evaluate/${courseId}/${test?.id}`,
        body: values,
        cache: 'no-cache',
    }))

    const validateTestAnswers = (): boolean => {
        let result = true
        testAnswers.forEach((value: TestAnswer) => {
            if (value.answerIds.length < 1) {
                result = false
            }
        })
        if (!result) {
            messageContext.setType(MessageType.warning)
            messageContext.setMessage(t('test.notAllAnswered'))
            messageContext.openModal(MODAL_DIALOG, true)
        }

        return result
    }

    const canShowTest = (): boolean => {
        if (test || error) {
            return true
        }
        return false
    }

    const handleAnswers = (questionId: number, answerIds: number[]): void => {
        setTestAnswers((prevState: TestAnswers) => {
            prevState.set(questionId, {
                questionId: questionId,
                answerIds: answerIds,
            })

            return prevState
        })
    }

    const handleEvaluateTest = (): void => {
        if (!validateTestAnswers()) {
            return
        }

        evaluateTest({
            answers: Array.from(testAnswers.values()),
        })
            .then((res: QueryResponse<EvaluatedTest>) => {
                if (res.error) {
                    messageContext.setType(MessageType.error)
                    messageContext.setMessage(t('error.errorTryAgain'))
                    messageContext.openModal(MODAL_DIALOG, true)
                    return
                }
                if (res.payload) {
                    setEvaluatedTest(res.payload)
                    return
                }
            })
            .catch((err) => {
            })
    }

    const handleBackToCourse = (): void => {
        navigate(`/app/do/course/${courseId}`)
    }

    const handleNext = (): void => {
        navigate(`/app/complete/course/${courseId}`)
    }

    const handleGoHome = (): void => {
        navigate(`/app`)
    }

    useEffect(() => {
        if (payload) {
            setTest(payload)
        }
    }, [payload])

    return (
        <TestContext.Provider
            value={{
                test: {
                    test: test,
                    loading: loading,
                    error: error,
                },
                evaluateTest: {
                    evaluatedTest: evaluatedTest,
                    loading: evaluateTestIsLoading,
                    error: evaluateTestError,
                },
                showTest: canShowTest(),
                handleAnswers: handleAnswers,
                handleEvaluateTest: handleEvaluateTest,
                handleBackToCourse: handleBackToCourse,
                handleGoHome: handleGoHome,
                handleNext: handleNext
            }}
        >
            {props.children}
        </TestContext.Provider>
    )
}
