import { Alert, Avatar, Button, Card, CardContent, Grid, Modal, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { UserDetails } from '../../../types'
import { Person } from '@mui/icons-material'
import { ChangePassword } from './components/changePassword'
import { useTranslation } from 'react-i18next'

interface Props {
    open: boolean
    handleClose: VoidFunction
    userDetails: UserDetails | undefined
    loading: boolean
}

export function ProfilePage(props: Props): JSX.Element {
    const [passwordChanged, setPasswordChanged] = useState(false)
    const [changePasswordModalOpen, setChangePasswordModalOpen] = useState<boolean>(false)
    const {t} = useTranslation()
    const handleOpen = () => {
        setChangePasswordModalOpen(true)
    }
    const handleClose = () => {
        setChangePasswordModalOpen(false)
    }

    useEffect(() => {
        if (props.open) {
            setPasswordChanged(false)
        }
    }, [props.open])

    return (
        <Modal
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="User profile"
            aria-describedby="Details about user profile"
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
            <Card
                variant="outlined"
                sx={{ width: '90%', maxHeight: '90vh', maxWidth: '600px', paddingBottom: 2, overflow: 'auto' }}
            >
                <CardContent>
                    <Grid container spacing={2} justifyContent="center" alignItems="center" textAlign="center">
                        <Grid item xs={12}>
                            <Stack justifyContent="center" alignItems="center" spacing={2}>
                                <Typography variant="h5">{t('user.profile')}</Typography>
                                <Avatar sx={{ width: 100, height: 100 }}>
                                    <Person sx={{ fontSize: '80px' }} />
                                </Avatar>
                                <Stack>
                                    <Typography>{props.userDetails?.firstName} {props.userDetails?.lastName}</Typography>
                                    <Typography variant="subtitle2">{props.userDetails?.firmName}, {props.userDetails?.firmPartName}</Typography>
                                </Stack>
                                {passwordChanged && <Alert severity="success">{t('user.passwordChange.success')}</Alert>}
                                <Stack direction="row" spacing={1}>
                                    <ChangePassword
                                        setPasswordChanged={setPasswordChanged}
                                        open={changePasswordModalOpen}
                                        handleClose={handleClose}
                                        handleOpen={handleOpen}
                                    />
                                    <Button variant="contained" color="error" onClick={props.handleClose}>
                                        {t('common.cancel')}
                                    </Button>
                                </Stack>
                            </Stack>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Modal>
    )
}
