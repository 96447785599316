import React, { useEffect, useMemo, useState } from 'react'
import { type DrawerContextType } from '../../types/context/DrawerContextType'
import { DrawerType } from '../../types/Enum/DrawerType'
import { DrawerStatus } from '../../types/Enum/DrawerStatus'
import { ResponsiveContext } from './ResponsiveContextProvider'

export const DrawerContext = React.createContext<DrawerContextType | null>(null)

interface Props {
    children?: React.ReactNode
}

const drawerWidth = 300

export const DrawerContextProvider = (props: Props): JSX.Element => {
    const [openDrawer, setOpenDrawer] = useState<boolean>(false)
    const [drawerType, setDrawerType] = useState<DrawerType>(DrawerType.persistent)
    const responsiveContext = React.useContext(ResponsiveContext)

    useEffect(() => {
        if (responsiveContext === null || !responsiveContext.isTabletOrMobile) {
            setDrawerType(DrawerType.persistent)
            setOpenDrawer(true)
        } else {
            setDrawerType(DrawerType.temporary)
            setOpenDrawer(false)
        }
    }, [responsiveContext?.isTabletOrMobile])

    const contentMarginLeft = useMemo((): string => {
        let marginLeft = 0
        if (drawerType === DrawerType.persistent) {
            marginLeft = drawerWidth
        }

        return marginLeft.toString() + 'px'
    }, [drawerType])

    const drawerStatus = useMemo((): DrawerStatus => {
        return openDrawer ? DrawerStatus.open : DrawerStatus.close
    }, [openDrawer])

    const handleCloseDrawer = (): void => {
        setOpenDrawer(false)
    }

    const handleOpenDrawer = (): void => {
        setOpenDrawer(true)
    }

    return (
        <DrawerContext.Provider
            value={{
                drawerWidth,
                contentMarginLeft,
                drawerType,
                isOpenDrawer: openDrawer,
                drawerStatus,
                handleCloseDrawer,
                handleOpenDrawer
            }}
        >
            {props.children}
        </DrawerContext.Provider>
    )
}
