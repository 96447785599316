import { useQuery } from 'react-fetching-library'
import { Alert, Skeleton, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { PasswordInfo } from '../../types'
import { useTranslation } from 'react-i18next'

export function PasswordValidationInfo(): JSX.Element {
    const {t} = useTranslation()
    const [passwordInfo, setPasswordInfo] = useState<string | null>(null)
    const { payload, loading } = useQuery<PasswordInfo>({
        method: 'GET',
        endpoint: '/user/password/info',
    })

    useEffect(() => {
        if (payload) {
            setPasswordInfo(payload.info)
        }
    }, [payload])

    function getPasswordInfo(): JSX.Element {
        if (loading || passwordInfo === null)
            return (
                <>
                    <Skeleton animation="wave" variant="rectangular" height="20px" />
                    <Skeleton animation="wave" variant="rectangular" height="20px" />
                    <Skeleton animation="wave" variant="rectangular" height="20px" />
                    <Skeleton animation="wave" variant="rectangular" height="20px" />
                    <Skeleton animation="wave" variant="rectangular" height="20px" />
                </>
            )

        const x = passwordInfo.split(',').map((value, key) => {
            return <Typography key={key}>{value}</Typography>
        })
        return <>{x}</>
    }

    return (
        <Alert severity="info">
            <Stack spacing={1} textAlign="start">
                <Typography variant="h6">{t('user.passwordChange.text')}</Typography>
                <br />
                {getPasswordInfo()}
            </Stack>
        </Alert>
    )
}
