import {
    Box,
    Button,
    Drawer,
    IconButton,
    List,
    ListItemButton,
    ListItem,
    Stack,
    ListItemIcon,
    ListItemText,
} from '@mui/material'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Inventory, Menu, MenuBook } from '@mui/icons-material'
import { theme } from '../../../../theme'
import {useTranslation} from "react-i18next";

export function NavigationMenu(): JSX.Element {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [openDrawer, setOpenDrawer] = useState<boolean>(false)

    function toggleDrawer() {
        setOpenDrawer(!openDrawer)
    }

    return (
        <>
            {/*Desktop view */}
            <Stack direction="row" spacing={3} display={{ xs: 'none', md: 'flex' }}>
                <Button
                    startIcon={<MenuBook />}
                    sx={{ color: theme.palette.primary.contrastText }}
                    onClick={() => {
                        navigate('/app')
                    }}
                >
                    {t('navigationMenu.activeCourses')}
                </Button>
                <Button
                    startIcon={<Inventory />}
                    sx={{ color: theme.palette.primary.contrastText }}
                    onClick={() => {
                        navigate('/app/course/archived')
                    }}
                >
                    {t('navigationMenu.archivedCourses')}
                </Button>
            </Stack>
            {/* Mobile/tablet view */}
            <Stack direction="row" alignItems="center" spacing={3} display={{ xs: 'flex', md: 'none' }}>
                <IconButton
                    sx={{ color: theme.palette.primary.contrastText }}
                    onClick={() => {
                        toggleDrawer()
                    }}
                >
                    <Menu />
                </IconButton>
            </Stack>
            <Drawer
                anchor={'left'}
                open={openDrawer}
                onClose={() => {
                    setOpenDrawer(false)
                }}
            >
                <List>
                    <ListItem>
                        <ListItemButton
                            onClick={() => {
                                setOpenDrawer(false)
                                navigate('/app')
                            }}
                        >
                            <ListItemIcon>
                                <MenuBook color="success" />
                            </ListItemIcon>
                            <ListItemText primary={t('navigationMenu.activeCourses')} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton
                            onClick={() => {
                                setOpenDrawer(false)
                                navigate('/app/course/archived')
                            }}
                        >
                            <ListItemIcon>
                                <Inventory color="primary" />
                            </ListItemIcon>
                            <ListItemText primary={t('navigationMenu.archivedCourses')} />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Drawer>
        </>
    )
}
