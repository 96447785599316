import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { ResponsiveContextType } from '../../types/context/ResponsiveContextType'

export const ResponsiveContext = React.createContext<ResponsiveContextType | null>(null)

interface Props {
    children?: React.ReactNode
}

export const ResponsiveContextProvider = (props: Props): JSX.Element => {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 900px)' })

    return (
        <ResponsiveContext.Provider
            value={{
                isTabletOrMobile: isTabletOrMobile,
            }}
        >
            {props.children}
        </ResponsiveContext.Provider>
    )
}
