import {BrowserRouter, Routes, Route} from 'react-router-dom'
import React, {type ReactElement, useEffect, useState} from 'react'
import {type AuthContextType} from '../types'
import {AuthProvider} from '../utils/AuthProvider'
import {RequireAuth} from '../utils/AuthRequired'
import {ThemeProvider} from '@mui/material/styles'
import {useTranslation} from 'react-i18next'
import {createClient, ClientContextProvider, createCache, type QueryResponse} from 'react-fetching-library'
import {RequestHostInterceptor} from '../utils/RequestInterceptor'
import {ResponseInterceptor} from '../utils/ResponseInterceptor'
import {AppLayout} from './pages/Layout/appLayout'
import {ArchiveCourses} from './pages/ArchiveCoursesPage/Loadable'
import {ActualCourses} from './pages/ActualCoursesPage/Loadable'
import {RequestResetPassword} from './pages/RequestResetPassword/Loadable'
import {LoginPage} from './pages/LoginPage/Loadable'
import {NotFoundPage} from './pages/NotFoundPage/Loadable'
import {DoCoursePage} from './pages/DoCoursePage/Loadable'
import {DoTestPage} from './pages/DoTestPage/Loadable'
import {DocReaderPage} from './pages/DocReaderPage/Loadable'
import {CompleteCoursePage} from './pages/CompleteCoursePage/Loadable'
import {ArchiveCoursePage} from './pages/ArchiveCoursePage/Loadable'
import {LoginByTokenPage} from './pages/LoginByTokenPage/Loadable'
import {Registration} from './pages/Registration/Loadable'
import {RegistrationForm} from './pages/RegistrationForm/Loadable'
import {theme} from '../theme'
import dayjs from 'dayjs'
import {ResetPassword} from './pages/ResetPassword/Loadable'
import {MessageContextProvider} from './context/MessageContextProvider'
import {CourseLayout} from './pages/Layout/courseLayout'
import {ResponsiveContextProvider} from './context/ResponsiveContextProvider'
import {ModalDialog} from './components/ModalDialog'
import {TestContextProvider} from './pages/DoTestPage/components/TestContextProvider'
import { RegistrationCourse } from './pages/RegistrationCourse/Loadable'

// User Auth at App mount AuthContext is null check AuthProvider.tsx for more
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const AuthContext = React.createContext<AuthContextType>(null!)

// login path
export const loginPath = '/'

const host = window.location.protocol + '//' + window.location.host + '/api'

export function App(): ReactElement {
    // lifted state Token
    const [token, setToken] = useState<string | undefined>(localStorage.getItem('JWT') ?? undefined)
    // Translation
    const {i18n} = useTranslation() // Translation
    // dayjs datetime format
    dayjs().locale(i18n.resolvedLanguage).format()

    const cache = createCache<QueryResponse<any>>(
        (action) => {
            return action.cache !== 'no-cache'
        },
        (response) => {
            // Ak sa opakuje request do 3 sekund
            return new Date().getTime() - response.timestamp < 3000
        }
    )
    const client = createClient({
        requestInterceptors: [
            RequestHostInterceptor(host, i18n.resolvedLanguage, token)
        ],
        responseInterceptors: [
            ResponseInterceptor(() => {
                localStorage.removeItem('JWT')
                localStorage.removeItem('username')
                if (window.location.pathname !== loginPath) {
                    window.location.replace(loginPath)
                }
            })
        ],
        cacheProvider: cache
    })

    // In special cases can token was set to undefined as string!!! just in case that happens again we log out user
    useEffect(() => {
        if (token === 'undefined') {
            localStorage.removeItem('JWT')
            localStorage.removeItem('username')
            if (window.location.pathname !== loginPath) {
                window.location.replace(loginPath)
            }
        }
    }, [token])

    return (
        <ClientContextProvider client={client}>
            <AuthProvider setToken={setToken}>
                <ResponsiveContextProvider>
                    <ThemeProvider theme={theme}>
                        <MessageContextProvider>
                            <BrowserRouter>
                                <Routes>
                                    {/* Public */}
                                    <Route path={loginPath} element={<LoginPage/>}/>
                                    <Route path="/login_by_token/:token" element={<LoginByTokenPage/>}/>
                                    <Route path="/register/:token" element={<Registration/>}/>
                                    <Route path="/registration/:token" element={<RegistrationForm/>}/>
                                    <Route path="/registration/course/:token" element={<RegistrationCourse/>}/>
                                    <Route
                                        path="/request/reset/password/:resetIdentifier"
                                        element={<RequestResetPassword/>}
                                    />
                                    <Route path="/reset/password/:token" element={<ResetPassword/>}/>
                                    {/* Requires to be authenticated */}
                                    <Route path={'/app'} element={<RequireAuth loginPath={loginPath} />}>
                                        <Route element={<AppLayout/>}>
                                            <Route index element={<ActualCourses/>}/>
                                            <Route path={'course/archived'} element={<ArchiveCourses/>}/>
                                        </Route>
                                        <Route element={<CourseLayout/>}>
                                            <Route
                                                path={'doc/reader/:trainingId/:chapterId/:slideId/:docId/:docPartId'}
                                                element={<DocReaderPage/>}
                                            />
                                            <Route
                                                path={'doc/reader/:trainingId/:chapterId/:slideId/:docId'}
                                                element={<DocReaderPage/>}
                                            />
                                            <Route path={'do/course/:courseId'} element={<DoCoursePage/>}/>
                                            <Route path={'course/archived/:courseId'} element={<ArchiveCoursePage/>}/>
                                            <Route
                                                path={'do/test/:courseId'}
                                                element={
                                                    <TestContextProvider>
                                                        <DoTestPage/>
                                                    </TestContextProvider>
                                                }
                                            />
                                            <Route
                                                path={'complete/course/:courseId'}
                                                element={<CompleteCoursePage/>}
                                            />
                                        </Route>
                                    </Route>
                                    {/* Not found */}
                                    <Route path="*" element={<NotFoundPage/>}/>
                                </Routes>
                            </BrowserRouter>
                            <ModalDialog/>
                        </MessageContextProvider>
                    </ThemeProvider>
                </ResponsiveContextProvider>
            </AuthProvider>
        </ClientContextProvider>
    )
}
