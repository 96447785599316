import * as React from 'react'
import { lazyLoad } from '../../../utils/Loadable'
import { LoadingIndicator } from '../../components/LoadingIndicator'
import { Box } from '@mui/material'

export const DoTestPage = lazyLoad(
    () => import('./index'),
    (module) => module.DoTestPage,
    {
        fallback: (
            <Box
                sx={{
                    width: '100%',
                    height: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <LoadingIndicator />
            </Box>
        ),
    }
)
