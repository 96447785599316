import sk from 'svg-country-flags/svg/sk.svg';
import cz from 'svg-country-flags/svg/cz.svg';
import gb from 'svg-country-flags/svg/gb.svg';
import pl from 'svg-country-flags/svg/pl.svg';
import hu from 'svg-country-flags/svg/hu.svg';
import bg from 'svg-country-flags/svg/bg.svg';
import ro from 'svg-country-flags/svg/ro.svg';
import { CSSProperties } from 'react';

interface Props {
    code: string;
    width: string;
    style?: CSSProperties | undefined
}

export default function CustomFlag(props: Props) {
    const { code, width } = props;

    let flagSrc;
    switch (code) {
        case 'sk':
            flagSrc = sk;
            break;
        case 'cz':
            flagSrc = cz;
            break;
        case 'gb':
            flagSrc = gb;
            break;
        case 'pl':
            flagSrc = pl;
            break;
        case 'hu':
            flagSrc = hu;
            break;
        case 'bg':
            flagSrc = bg;
            break;
        case 'ro':
            flagSrc = ro;
            break;
        default:
            flagSrc = '';
    }

    return (
        <img style={props.style} src={flagSrc} width={width} alt={code} />
    );
}
