import React, {useEffect, useState} from 'react'
import { type AuthContextType, type SignInCallback, type User } from '../types'
import {type Action, useMutation} from 'react-fetching-library'
import { AuthContext } from '../app'
import { useTranslation } from 'react-i18next'

interface Props {
    children?: React.ReactNode
    setToken: (value: string | undefined) => void
}
export interface AuthResponse {
    token?: string
    message?: string
    payload: {
        isLoggedBefore: boolean
        isEulaAgreed: boolean
    }
}

interface LoginValues {
    username: string
    password: string
}

const loginActionCreator = (values: LoginValues): Action => {
    return {
        method: 'POST',
        endpoint: '/authentication_token',
        body: values
    }
}

export function AuthProvider (props: Props): React.ReactElement {
    const { t } = useTranslation()
    // Load Token from local storage (token)
    const [user, setUser] = useState<User | null>({
        username: localStorage.getItem('username') ?? undefined,
        token: localStorage.getItem('JWT') ?? undefined
    })
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const { loading, mutate } = useMutation(loginActionCreator)

    const signIn = (user: string, password: string, callback: SignInCallback): void => {
        void mutate({ username: user, password }).then((result) => {
            const payload: AuthResponse | undefined = result.payload
            if (payload === undefined) {
                setErrorMessage(t('AuthError'))
                return
            }

            if (!result.error) {
                props.setToken(payload.token)
                setUser({ token: payload.token, username: user })
                callback(payload) // POZOR pred callBack musi byt token už setnuty
            } else {
                // Error
                if (result) {
                    setErrorMessage(payload.message ?? t('AuthError'))
                }
            }
        })
    }

    const signInByToken = (response: AuthResponse): boolean => {
        const jwtToken = response.token
        // Get username from JWT token
        const tokenPayload = jwtToken?.split('.')[1]
        const usernameDecoded = tokenPayload ? JSON.parse(atob(tokenPayload)).username : null
        if (usernameDecoded == null) {
            return false
        }

        props.setToken(response.token)
        setUser({ token: response.token, username: usernameDecoded })

        return true
    }

    const signOut = (callback: VoidFunction): void => {
        localStorage.removeItem('JWT')
        localStorage.removeItem('username')
        setUser(null)
        callback()
    }

    const refreshToken = (token: string): void => {
        setUser({...user, token: token})
        props.setToken(token)
    }


    // Save Token
    useEffect((): void => {
        if (user?.token != null && user?.username != null) {
            localStorage.setItem('JWT', user.token)
            localStorage.setItem('username', user.username)
        }
    }, [user])

    const authContext: AuthContextType = {
        user,
        setUser,
        signIn,
        signInByToken,
        signOut,
        loading,
        errorMessage,
        refreshToken
    }

    return <AuthContext.Provider value={authContext}>{props.children}</AuthContext.Provider>
}
