import { IconButton, Menu, MenuItem, Stack, Typography } from '@mui/material'
import { languageCodeToFlagCode, languages } from '../../../../locales/languages'
import React from 'react'
import {useTranslation} from 'react-i18next'
import { theme } from '../../../../theme'
import CustomFlag from "../../../../locales/CustomFlag";

export function LanguageSwitch(): JSX.Element {
    const {i18n} = useTranslation();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton sx={{color: theme.palette.common.black}} onClick={handleClick}>
                <CustomFlag
                    code={ languageCodeToFlagCode(i18n.resolvedLanguage) }
                    width="26"
                />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {Object.values(languages).map((language) => (
                    <MenuItem
                        onClick={() => {
                            i18n.changeLanguage(language.code)
                            handleClose()
                        }}
                        key={language.code}
                        value={language.code}
                        selected={language.code === i18n.resolvedLanguage}
                    >
                        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1} paddingTop={1}>
                            <CustomFlag code={ language.flagCode } width="26" />
                            <Typography variant="button">{language.code}</Typography>
                        </Stack>
                    </MenuItem>
                ))}
            </Menu>
        </>
    )
}