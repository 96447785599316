import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    Grid,
    Modal,
    Stack,
    TextField,
    Typography,
} from '@mui/material'
import { Key } from '@mui/icons-material'
import { PasswordValidationInfo } from '../../../components/passwordValidationInfo'
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-fetching-library'
import { ChangePasswordData } from '../../../../types'
import { useTranslation } from 'react-i18next'

interface Props {
    open: boolean
    handleClose: VoidFunction
    handleOpen: VoidFunction
    setPasswordChanged: Function
}

export function ChangePassword(props: Props): JSX.Element {
    const {t} = useTranslation()
    const [oldPassword, setOldPassword] = useState<string>('')
    const [oldPasswordError, setOldPasswordError] = useState<string | null>(null)
    const [newPassword, setNewPassword] = useState<string>('')
    const [newPasswordError, setNewPasswordError] = useState<string | null>(null)
    const [repeatNewPassword, setRepeatNewPassword] = useState<string>('')
    const [repeatNewPasswordError, setRepeatNewPasswordError] = useState<string | null>(null)

    const { loading, payload, mutate, reset } = useMutation<ChangePasswordData>(
        (values: { oldPlainPassword: string; newPlainPassword: string }) => ({
            method: 'POST',
            endpoint: '/user/change/password',
            body: values,
        })
    )

    function resetErrors() {
        setOldPasswordError(null)
        setNewPasswordError(null)
        setRepeatNewPasswordError(null)
    }

    function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault()
        resetErrors()
        let localErrors = 0
        if (oldPassword.length === 0) {
            setOldPasswordError(t('user.valid.oldPassword'))
            localErrors++
        }
        if (newPassword.length === 0) {
            setNewPasswordError(t('user.valid.newPassword'))
            localErrors++
        }
        if (repeatNewPassword.length === 0) {
            setRepeatNewPasswordError(t('user.valid.repeatNewPassword'))
            localErrors++
        }
        if (newPassword !== repeatNewPassword) {
            setRepeatNewPasswordError(t('user.valid.passwordMatch'))
            localErrors++
        }
        if (localErrors === 0) {
            mutate({ oldPlainPassword: oldPassword, newPlainPassword: newPassword }).then()
        }
    }

    useEffect(() => {
        if (payload && payload.errors.length > 0) {
            //Setneme na prazdy string aby svietili inputy
            setOldPasswordError('')
            setNewPasswordError('')
            setRepeatNewPasswordError('')
        }
        if (payload && payload.errors.length === 0) {
            props.handleClose()
            props.setPasswordChanged(true)
        }
    }, [payload])

    useEffect(() => {
        resetErrors()
        reset()
        setOldPassword('')
        setNewPassword('')
        setRepeatNewPassword('')
    }, [props.open])

    return (
        <>
            <Button onClick={props.handleOpen} variant="contained" startIcon={<Key />}>
                {t('user.changePassword')}
            </Button>
            <Modal
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="User profile"
                aria-describedby="Details about user profile"
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
                <Card
                    variant="outlined"
                    sx={{
                        width: '90%',
                        maxHeight: '90vh',
                        maxWidth: '900px',
                        padding: 5,
                        paddingTop: 1,
                        overflow: 'auto',
                    }}
                >
                    <CardContent>
                        <Grid container spacing={5} justifyContent="center" alignItems="start" textAlign="center">
                            <Grid item xs={12}>
                                <Typography variant="h5" marginBottom="10px">
                                    {t('user.changePassword')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={7}>
                                {payload && payload.errors.length > 0 ? (
                                    <Alert severity="error">
                                        <AlertTitle>{t('alert.title')}</AlertTitle>
                                        <Stack spacing={1}>
                                            {payload.errors.map((error, index) => {
                                                return <Typography key={index}>{error}</Typography>
                                            })}
                                        </Stack>
                                    </Alert>
                                ) : (
                                    <PasswordValidationInfo />
                                )}
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <Box component="form" onSubmit={handleSubmit}>
                                    <Stack spacing={3}>
                                        <TextField
                                            error={oldPasswordError !== null}
                                            helperText={oldPasswordError}
                                            label={t('user.passwordChange.actual')}
                                            variant="standard"
                                            value={oldPassword}
                                            onChange={(event) => {
                                                setOldPassword(event.currentTarget.value)
                                            }}
                                            type="password"
                                        />
                                        <TextField
                                            error={newPasswordError !== null}
                                            helperText={newPasswordError}
                                            label={t('user.passwordChange.new')}
                                            variant="standard"
                                            value={newPassword}
                                            onChange={(event) => {
                                                setNewPassword(event.currentTarget.value)
                                            }}
                                            type="password"
                                        />
                                        <TextField
                                            error={repeatNewPasswordError !== null}
                                            helperText={repeatNewPasswordError}
                                            label={t('user.passwordChange.repeat')}
                                            variant="standard"
                                            value={repeatNewPassword}
                                            onChange={(event) => {
                                                setRepeatNewPassword(event.currentTarget.value)
                                            }}
                                            type="password"
                                        />
                                        <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
                                            {loading ? (
                                                <CircularProgress />
                                            ) : (
                                                <>
                                                    <Button variant="contained" type="submit">
                                                        {t('user.changePassword')}
                                                    </Button>
                                                    <Button
                                                        variant="contained"
                                                        color="error"
                                                        onClick={props.handleClose}
                                                    >
                                                        {t('common.cancel')}
                                                    </Button>
                                                </>
                                            )}
                                        </Stack>
                                    </Stack>
                                </Box>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Modal>
        </>
    )
}
