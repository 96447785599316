import React from 'react'
import { Box, styled } from '@mui/material'
import { Outlet } from 'react-router-dom'
import { DrawerContextProvider } from '../../context/DrawerContextProvider'

const Main = styled('main')(({ theme }) => ({
    flexGrow: 1,
    //padding: theme.spacing(3),
}))

export const CourseLayout = (): JSX.Element => {
    return (
        <DrawerContextProvider>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                }}
            >
                <Main>
                    <Outlet />
                </Main>
            </Box>
        </DrawerContextProvider>
    )
}
