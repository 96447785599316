import { useNavigate, Outlet } from 'react-router-dom'
import React, { useState } from 'react'
import AppBar from '@mui/material/AppBar'
import { useTranslation } from 'react-i18next'
import {
    Box,
    Toolbar,
    IconButton,
    styled,
    Divider,
    Stack,
    Typography,
    CircularProgress,
} from '@mui/material'
import { AuthContext } from '../../index'
import { AccountCircle, PowerSettingsNew } from '@mui/icons-material'
import { ProfilePage } from '../ProfilePage'
import { LanguageSwitch } from './components/languageSwitch'
import { NavigationMenu } from './components/navigationMenu'
import { useQuery } from 'react-fetching-library'
import { UserDetails } from '../../../types'
import { theme } from '../../../theme'
import palette from '../../../theme/palette'

const Main = styled('main')(({ theme }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
}))

export const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}))

export function AppLayout(): JSX.Element {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const auth = React.useContext(AuthContext)
    const [openProfile, setOpenProfile] = useState<boolean>(false)

    const { loading, payload } = useQuery<UserDetails>({
        method: 'GET',
        endpoint: '/user/details',
    })

    function renderUserName(): JSX.Element {
        if (loading) return <CircularProgress size={20} sx={{ color: theme.palette.primary.contrastText }} />
        return (
            <Typography variant='subtitle1' style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>
                {payload?.firstName} {payload?.lastName}
            </Typography>
        )
    }

    function renderUserFirm(): JSX.Element {
        if (loading) return <CircularProgress size={20} sx={{ color: theme.palette.primary.contrastText }} />
        return (
            <Typography variant='subtitle1' style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>
                {payload?.firmName}
            </Typography>
        )
    }

    return (
        <Box
            component='main'
            sx={{
                flexGrow: 1,
            }}
        >
            {/* Top app bar */}
            <AppBar
                position='fixed'
                sx={{
                    backgroundColor: palette.main.blue,
                }}
            >
                <Toolbar>
                    <Stack direction='row' justifyContent='space-between' sx={{ width: '100%' }}>
                        <NavigationMenu />
                        <Stack direction='row' spacing={2} alignItems='center'>
                            <Box display={{ xs: 'none', sm: 'flex' }}>{renderUserFirm()}</Box>
                            <Divider
                                orientation='vertical'
                                style={{ backgroundColor: theme.palette.primary.contrastText }}
                            />

                            <LanguageSwitch />
                            <Divider
                                orientation='vertical'
                                style={{ backgroundColor: theme.palette.primary.contrastText }}
                            />

                            <Stack direction='row' alignItems='center'>
                                <IconButton
                                    size='large'
                                    aria-label='account of current user'
                                    aria-controls='menu-appbar'
                                    aria-haspopup='true'
                                    onClick={() => {
                                        setOpenProfile(true)
                                    }}
                                    color='inherit'
                                >
                                    <Stack direction={'row'} spacing={1}>
                                        <AccountCircle />
                                        <Box sx={{
                                            display: {xs: 'none', sm: 'block'}
                                        }}>
                                            {renderUserName()}
                                        </Box>
                                    </Stack>
                                </IconButton>
                            </Stack>
                            <Divider
                                orientation='vertical'
                                style={{ backgroundColor: theme.palette.primary.contrastText }}
                            />
                            <IconButton
                                onClick={() => {
                                    auth.signOut(() => navigate('/'))
                                }}
                                color="error"
                            >
                                <PowerSettingsNew />
                            </IconButton>
                        </Stack>
                    </Stack>
                </Toolbar>
            </AppBar>
            {/* Main */}
            <Main>
                <DrawerHeader />
                <Outlet />
                <ProfilePage
                    open={openProfile}
                    loading={loading}
                    userDetails={payload}
                    handleClose={() => {
                        setOpenProfile(false)
                    }}
                />
            </Main>
        </Box>
    )
}
