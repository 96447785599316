import { Languages } from '../types'

export function languageCodeToFlagCode(code: string): string
{
    if(code === 'cs') return 'cz'
    if(code === 'en') return 'gb'
    return code
}

export const languages: Languages = {
    sk: { nativeName: 'Slovak', flagCode: 'sk', code: 'sk'},
    cs: { nativeName: 'Czech', flagCode: 'cz', code: 'cs' },
    en: { nativeName: 'English', flagCode: 'gb', code: 'en'  },
    pl: { nativeName: 'Polish', flagCode: 'pl', code: 'pl'  },
    hu: { nativeName: 'Hungarian', flagCode: 'hu', code: 'hu'  },
    bg: { nativeName: 'Bulgarian', flagCode: 'bg', code: 'bg'  },
    ro: { nativeName: 'Romanian', flagCode: 'ro', code: 'ro'  },
}