import {Button, Stack, Typography} from "@mui/material";
import React from "react";
import {Refresh} from "@mui/icons-material";
import {useTranslation} from "react-i18next";

interface Props {
    retry?: Function
    args?: any
}

export function QueryError(props: Props): JSX.Element {
    const {t} = useTranslation();

    return (
        <Stack padding={5} spacing={2} textAlign="center" justifyContent="center" alignItems="center">
            <Typography variant="h6" color="primary">
                {t('error.errorTryAgain')}
            </Typography>
            {props.retry &&
                <Button variant="contained" sx={{ maxWidth: "200px"}} color="error" onClick={() => {
                    props.args ? props.retry!(props.args) : props.retry!()
                }
                } startIcon={<Refresh />}>
                    {t('error.retry')}
                </Button>
            }
        </Stack>
    )

}