import { Action, QueryResponse } from 'react-fetching-library'

export const ResponseInterceptor = (unauthorizedCallBack: (action: Action, response: QueryResponse) => void) =>
    () =>
    async (action: Action, response: QueryResponse) => {
        if (response.error && response.status === 401) {
            unauthorizedCallBack(action, response)
        }

        return response
    }
